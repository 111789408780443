// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  margin-bottom: 20px;
  width: 35%;
}

.errormsg {
  position: absolute;
  margin-top: 33px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/login/login.component.scss"],"names":[],"mappings":"AAAA;EAAO,mBAAA;EACH,UAAA;AAEJ;;AADA;EACI,kBAAA;EACJ,gBAAA;AAIA","sourcesContent":[".logo{ margin-bottom: 20px;\r\n    width: 35%;}\r\n.errormsg{\r\n    position: absolute;\r\nmargin-top: 33px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
