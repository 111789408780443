// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-md-8.mx-auto {
    margin-top: 100px;
}
h1 {
    margin-bottom: 30px;
}
h3{
    font-size: 18px;
    padding-bottom: 15px;
}
.form-group.col-sm-4 {
    padding: 0px;
}
label {
    margin-left: 5px;
}
p {
    color: red;
    font-size: 0.8rem;
    margin-bottom: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/app/views/switch-role/switch-role.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,oBAAoB;AACxB;AACA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;EACpB","sourcesContent":[".col-md-8.mx-auto {\r\n    margin-top: 100px;\r\n}\r\nh1 {\r\n    margin-bottom: 30px;\r\n}\r\nh3{\r\n    font-size: 18px;\r\n    padding-bottom: 15px;\r\n}\r\n.form-group.col-sm-4 {\r\n    padding: 0px;\r\n}\r\nlabel {\r\n    margin-left: 5px;\r\n}\r\np {\r\n    color: red;\r\n    font-size: 0.8rem;\r\n    margin-bottom: 5px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
