import { Component, Input } from "@angular/core";
import { AbstractControlDirective, AbstractControl } from "@angular/forms";

@Component({
  selector: 'app-error-messages',
  templateUrl: './error-messages.component.html',
  styleUrls: ['./error-messages.component.css']
})
export class ErrorMessagesComponent {

  private readonly errorMessages = {
    required: () => "This field is required",
    email: () => "Enter a valid email",
    minlength: params =>
      "The min number of characters is " + params.requiredLength,
    maxlength: params =>
      "The max allowed number of characters is " + params.requiredLength,
    pattern: () => this.patternMessage(),
    years: params => params.message,
    mobileNumber: params => params.message,
    notOnlyZero: () => "Must be a non-zero value",
    only9digits: () => "This field accepts only numbers (up to 9 digits)"
  };

  patternMessage() {
    let errorText = "";
    if (this.controlName === "name") errorText = " Enter a valid Name";
    if (this.controlName === "phone") errorText = "Enter a valid phone number";
    if (this.controlName === "no_page") errorText = "Enter a valid number";
    if (this.controlName === "grant_amount") errorText = "This field accepts only numbers (up to 9 digits)";
    if (this.controlName === "funding_amount") errorText = "This field accepts only numbers (up to 9 digits)";
    if (this.controlName === "submit") errorText = "Please fill in all manadatory fields"
    return errorText;
  }

  @Input()
  private control!: AbstractControlDirective | AbstractControl;
  @Input()
  private controlName!: String;

  shouldShowErrors(): boolean | null {
    return (
      this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched)
    );
  }

  listOfErrors(): string[] {
    if (!this.control.errors) return;
    return Object.keys(this.control.errors).map(field =>
      this.getMessage(field, this.control.errors[field])
    );
  }

  private getMessage(type: string, params: any) {
    return this.errorMessages[type](params);
  }

}
