import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  url: string;

  constructor(readonly apiService: ApiService) { }

  getRequest(url) {
    return this.apiService.get(url);
  }
  postRequest(url, data) {
    return this.apiService.post(url, data);
  }

  getTrialRequest(url) {
    return this.apiService.gettrial(url);
  }
  updateRequest(url, data) {
    return this.apiService.update(url, data);
  }

  //-------------------------------Get Requests-----------------------------------

  getOtherEngagementType() {
    this.url = 'api/masterlookup/other-engement-type/';
    return this.apiService.get(this.url);
  }
  getEoiActivityType() {
    this.url = 'api/masterlookup/activity-type/';
    return this.apiService.get(this.url);
  }

  getEoiActivityForId(id) {
    this.url = `api/activity/${id}/`;
    return this.apiService.get(this.url);
  }

  getCurrencyType() {
    this.url = 'api/masterlookup/currency-type/';
    return this.apiService.get(this.url);
  }

  getFundingAgencyType() {
    this.url = 'api/masterlookup/funding-agency-type/';
    return this.apiService.get(this.url);
  }

  getDocumentType() {
    this.url = 'api/masterlookup/document-type/';
    return this.apiService.get(this.url);
  }

  getOtherEngagementTaskType() {
    this.url = 'api/masterlookup/otherenagement-task-type/';
    return this.apiService.get(this.url);
  }

  getTaskType() {
    this.url = 'api/masterlookup/task-type/';
    return this.apiService.get(this.url);
  }

  getProjectMeetingType() {
    this.url = 'api/masterlookup/project-meeting-type/';
    return this.apiService.get(this.url);
  }

  getMeetingType() {
    this.url = 'api/masterlookup/meeting-type/';
    return this.apiService.get(this.url);
  }

  getMeetingDetailView(id) {
    this.url = `api/meetingdetailview/${id}/`;
    return this.apiService.get(this.url);
  }

  getMinuteTakerView(id) {
    this.url = `api/minutetakerview/${id}/`;
    return this.apiService.get(this.url);
  }

  getMilestone(id) {
    this.url = `api/milestone/${id}/`;
    return this.apiService.get(this.url);
  }

  getReviewerDetail(id) {
    this.url = `api/detail-reviewer/${id}/`;
    return this.apiService.get(this.url);
  }

  switchUser(id) {
    this.url = `api/switchuser/${id}/`;
    return this.apiService.get(this.url);
  }

  getOrganisationDetail(id) {
    this.url = `api/detail-organization/${id}/`;
    return this.apiService.get(this.url);
  }

  getProjectType() {
    this.url = 'api/masterlookup/project-type/';
    return this.apiService.get(this.url);
  }

  getFunderList() {
    this.url = 'api/funders-list/';
    return this.apiService.get(this.url);
  }

  getAllProjectsList() {
    this.url = 'api/projects-all/';
    return this.apiService.get(this.url);
  }

  //------------------------------Update Requests----------------------------------

  editEngagement(id, data) {
    this.url = `api/edit-engagement/${id}/`;
    return this.apiService.update(this.url, data);
  }

  updateActivty(id, data) {
    this.url = `api/activity/${id}/`;
    return this.apiService.update(this.url, data);
  }

  editDocument(id, data) {
    this.url = `api/edit-document/${id}/`;
    return this.apiService.update(this.url, data);
  }
  updateEoi(id, data) {
    this.url = `api/eoi/${id}/`;
    return this.apiService.update(this.url, data);
  }

  updateTask(id, data) {
    this.url = `api/task/${id}/`;
    return this.apiService.update(this.url, data);
  }

  editMeetingDetail(id, data) {
    this.url = `api/edit-meetingdetail/${id}/`;
    return this.apiService.update(this.url, data);
  }

  updateMilestone(id, data) {
    this.url = `api/milestone/${id}/`;
    return this.apiService.update(this.url, data);
  }

  editReviewer(id, data) {
    this.url = `api/edit-reviewer/${id}/`;
    return this.apiService.update(this.url, data);
  }

  editOrganisation(id, data) {
    this.url = `api/edit-organization/${id}/`;
    return this.apiService.update(this.url, data);
  }

  editProject(id, data) {
    this.url = `api/edit-project/${id}/`;
    return this.apiService.update(this.url, data);
  }

  editProposal(id, data) {
    this.url = `api/edit-proposal/${id}/`;
    return this.apiService.update(this.url, data);
  }

  //------------------------------Post Requests------------------------------------
  getProjectList(pagenumber, data) {
    this.url = `api/project-listing/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  getCalenderTaskList(data) {
    this.url = 'api/calender-tasklisting/';
    return this.apiService.post(this.url, data);
  }

  getDashboadDatalisting(pagenumber, data) {
    this.url = `dashboard-datalisting/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  getDashboardApi(data) {
    this.url = 'dashboard-api/';
    return this.apiService.post(this.url, data);
  }

  getDashboardNotification(data) {
    this.url = 'dashboard-notification/';
    return this.apiService.post(this.url, data);
  }

  getPieChartData(data) {
    this.url = 'pie-chartdata/';
    return this.apiService.post(this.url, data);
  }

  userLogin(data) {
    this.url = 'api-login/';
    return this.apiService.post(this.url, data);
  }

  getAllRoles(data) {
    this.url = 'api/all-roles/';
    return this.apiService.post(this.url, data);
  }

  getProfile(data) {
    this.url = 'api/profile/';
    return this.apiService.post(this.url, data);
  }

  updateProfile(userprofileid, formData) {
    this.url = `api/update-profile/${userprofileid}/`;
    return this.apiService.post(this.url, formData);
  }

  getCpeheadTasklisting(pagenumber, data) {
    this.url = `api/cpehead-tasklisting/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  addTeam(data) {
    this.url = 'api/add-team/';
    return this.apiService.post(this.url, data);
  }

  editCpe(data) {
    this.url = 'api/edit-cpe/';
    return this.apiService.post(this.url, data);
  }

  getQchDoclisting(pagenumber, data) {
    this.url = `api/qch-doclisting/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  getRolebasedUserlisting(data) {
    this.url = 'api/rolebased-userlisting/';
    return this.apiService.post(this.url, data);
  }

  getDataCount(data) {
    this.url = `datacount-api/`;
    return this.apiService.post(this.url, data);
  }

  addEngagement(data) {
    this.url = 'api/add-engagement/';
    return this.apiService.post(this.url, data);
  }

  getMainMenu(data) {
    this.url = `api/main-menu/`;
    return this.apiService.post(this.url, data);
  }

  getEngagementDetail(id, data) {
    this.url = `api/engagement-detail/${id}/`;
    return this.apiService.post(this.url, data);
  }

  getEngagementListing(pagenumber, data) {
    this.url = `api/engagement-listing/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }
  getEoiMilestoneList(data) {
    this.url = 'api/eoi-milestonelist/';
    return this.apiService.post(this.url, data);
  }

  getMilestoneDate(data) {
    this.url = 'api/milestone-date/';
    return this.apiService.post(this.url, data);
  }

  getEoiActivity(data) {
    this.url = 'api/activity/';
    return this.apiService.post(this.url, data);
  }

  getMatHistory(data) {
    this.url = 'api/mat-history-api/';
    return this.apiService.post(this.url, data);
  }

  getEoiActivityListing(pagenumber, data) {
    this.url = `api/activity-listing/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  getEoiData(data) {
    this.url = 'api/eoi/';
    return this.apiService.post(this.url, data);
  }

  getAuthorListing(data) {
    this.url = 'api/author-listing/';
    return this.apiService.post(this.url, data);
  }

  getDocumentActivities(data) {
    this.url = 'api/document-activities/';
    return this.apiService.post(this.url, data);
  }

  uploadDoclisting(data) {
    this.url = 'api/upload-doclisting/';
    return this.apiService.post(this.url, data);
  }

  addDocument(data) {
    this.url = 'api/add-document/';
    return this.apiService.post(this.url, data);
  }

  getAssignList(data) {
    this.url = 'api/doc-assignelist/';
    return this.apiService.post(this.url, data);
  }

  getNextState(data) {
    this.url = 'api/doc-nextstates/';
    return this.apiService.post(this.url, data);
  }

  getDocumentDetails(data) {
    this.url = 'api/document-detail/';
    return this.apiService.post(this.url, data);
  }

  getDocumentHistory(data) {
    this.url = 'api/document-history/';
    return this.apiService.post(this.url, data);
  }

  qchApproveDocument(data) {
    this.url = `api/qchapprove-document/`;
    return this.apiService.post(this.url, data);
  }

  getDocumentListing(pagenumber, data) {
    this.url = `api/document-listing/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  getEoiDetail(id, data) {
    this.url = `api/detail-eoi/${id}/`;
    return this.apiService.post(this.url, data);
  }

  getTaskPersonList(data) {
    this.url = 'api/task-resperson/';
    return this.apiService.post(this.url, data);
  }

  getEntityActivities(data) {
    this.url = 'api/entity-activities/';
    return this.apiService.post(this.url, data);
  }

  getActiviyDate(data) {
    this.url = 'api/activity-date/';
    return this.apiService.post(this.url, data);
  }

  addTask(data) {
    this.url = 'api/add-task/';
    return this.apiService.post(this.url, data);
  }

  getTaskDetail(data) {
    this.url = 'api/task-detail/';
    return this.apiService.post(this.url, data);
  }

  getEntityTaskListing(pagenumber, data) {
    this.url = `api/entity-tasklisting/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  getInviteesList(data) {
    this.url = 'api/invitees-list/';
    return this.apiService.post(this.url, data);
  }

  getVetterList(data) {
    this.url = 'api/vetter-list/';
    return this.apiService.post(this.url, data);
  }

  addMeetingDetail(data) {
    this.url = 'api/add-meetingdetail/';
    return this.apiService.post(this.url, data);
  }

  getMeetingHistory(data) {
    this.url = 'api/meeting-history/';
    return this.apiService.post(this.url, data);
  }

  addMinuteTaker(data) {
    this.url = 'api/add-minutetaker/';
    return this.apiService.post(this.url, data);
  }

  getMeetingList(pagenumber, data) {
    this.url = `api/meeting-list/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  getMilestoneData(data) {
    this.url = 'api/milestone/';
    return this.apiService.post(this.url, data);
  }

  getMilestoneList(pagenumber, data) {
    this.url = `api/milestone-listing/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  getTaskList(pagenumber, data) {
    this.url = `api/task-list/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  addReviewer(data) {
    this.url = 'api/add-reviewer/';
    return this.apiService.post(this.url, data);
  }

  getTeamList(data) {
    this.url = 'api/listing-team/';
    return this.apiService.post(this.url, data);
  }

  getRoles(data) {
    this.url = 'api/roles/';
    return this.apiService.post(this.url, data);
  }

  getReviewerList(data) {
    this.url = 'api/listing-reviewer/';
    return this.apiService.post(this.url, data);
  }

  getEoiList(pagenumber, data) {
    this.url = `api/eoi-listing/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  getFunderDetails(data) {
    this.url = 'api/funder-details/';
    return this.apiService.post(this.url, data);
  }

  submitOtherEngagement(data) {
    this.url = 'api/submit-otherengagement/';
    return this.apiService.post(this.url, data);
  }

  closerOtherEngagement(data) {
    this.url = 'api/close-otherengagement/';
    return this.apiService.post(this.url, data);
  }

  convertEoi(data) {
    this.url = 'api/convert-to/';
    return this.apiService.post(this.url, data);
  }

  submitFunder(data) {
    this.url = 'api/submit-funder/';
    return this.apiService.post(this.url, data);
  }

  addOrganisation(data) {
    this.url = 'api/add-organization/';
    return this.apiService.post(this.url, data);
  }

  getOrganisationList(pagenumber, data) {
    this.url = `api/listing-organization/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  getProjectOutputType(data) {
    this.url = 'api/project-output-type/';
    return this.apiService.post(this.url, data);
  }

  addProject(data) {
    this.url = 'api/add-project/';
    return this.apiService.post(this.url, data);
  }

  projectCloseDetails(data) {
    this.url = 'api/proj-close-details/';
    return this.apiService.post(this.url, data);
  }

  closeProject(data) {
    this.url = 'api/close-project/';
    return this.apiService.post(this.url, data);
  }

  getProjectCompleteDetails(data) {
    this.url = 'api/proj-complete-details/';
    return this.apiService.post(this.url, data);
  }

  completeProject(data) {
    this.url = 'api/complete-project/';
    return this.apiService.post(this.url, data);
  }

  getProjectDetail(id, data) {
    this.url = `api/project-detail/${id}/`;
    return this.apiService.post(this.url, data);
  }

  getProjectHistory(data) {
    this.url = 'api/project-history/';
    return this.apiService.post(this.url, data);
  }

  getOnholdProjectDetails(data) {
    this.url = 'api/proj-on-hold-details/';
    return this.apiService.post(this.url, data);
  }

  getOnholdProjectData(data) {
    this.url = 'api/onhold-project/';
    return this.apiService.post(this.url, data);
  }

  getReactiveProjectData(data) {
    this.url = 'api/reactivate-project/';
    return this.apiService.post(this.url, data);
  }

  getRatingDetail(data) {
    this.url = 'api/rating-detail/';
    return this.apiService.post(this.url, data);
  }

  getRatingQuestionList(data) {
    this.url = 'api/ratingquestion-list/';
    return this.apiService.post(this.url, data);
  }

  addRating(data) {
    this.url = 'api/add-rating/';
    return this.apiService.post(this.url, data);
  }

  addProposal(data) {
    this.url = 'api/add-proposal/';
    return this.apiService.post(this.url, data);
  }

  getProposalDetail(id, data) {
    this.url = `api/detail-proposal/${id}/`;
    return this.apiService.post(this.url, data);
  }

  getProposalList(pagenumber, data) {
    this.url = `api/proposal-listing/?page=${pagenumber}`;
    return this.apiService.post(this.url, data);
  }

  getOuterChildMenu(data) {
    this.url = 'api/outerchild-menu/';
    return this.apiService.post(this.url, data);
  }

  getMatMilestoneList(data) {
    this.url = `api/mat-hierarchy-milestone-listing/`;
    return this.apiService.post(this.url, data);
  }

  getMatActivityList( data) {
    this.url = `api/mat-hierarchy-activity-listing/`;
    return this.apiService.post(this.url, data);
  }

  getMatTaskList( data) {
    this.url = `api/mat-hierarchy-task-listing/`;
    return this.apiService.post(this.url, data);
  }
}
