import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'app/_services/app.service';
import { CommonService } from 'app/core/common.service';

@Component({
  selector: 'app-newlogin',
  templateUrl: './newlogin.component.html',
  styleUrls: ['./newlogin.component.scss']
})
export class NewloginComponent {

  submitted = false;
  activityList = [];
  switchRoleId;
  switcholeName;
  load = true;
  result = '';
  key = '';
  constructor(private formBuilder: FormBuilder, private cmnService: CommonService, private appService: AppService,
    private router: Router, private actRoute: ActivatedRoute) {
    this.actRoute.queryParams.subscribe(
      (params: Params) => {
        this.key = params.key;
        //console.log("key " + this.key)
      });

  }

  ngOnInit(): void {
    this.appService.setUserLoggedIn(false)
    this.randomString(40, 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789/=@#$')
  }

  randomString(length, chars) {
    for (var i = length; i > 0; --i) this.result += chars[Math.floor(Math.random() * chars.length)];
    //console.log("result" + this.result)
    localStorage.setItem('uniqueId', `${this.result}`);
    return this.result;
  }

  onClickLogin() {
    this.cmnService.postRequest('get_ad_url/', { browser_id: this.result })
      .subscribe((response) => {
        if (response.status === 2) {
          localStorage.setItem('ad-token', response.token);
          this.appService.setUserLoggedIn(true)
          window.location.href = response.url;
        } else {
          alert(`${response.message}`);
          this.submitted = false;
          this.load = true;
        }
      })
  }

}
