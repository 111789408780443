
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { CommonService } from '../../core/common.service';
import { Router } from '@angular/router';
import { AppService } from '../../_services/app.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  submitted = false;
  activityList = [];
  switchRoleId;
  switcholeName;
  load = true;
  loginData = {
    username: "", password: ""
  }
  loginForm: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder, private cmnService: CommonService, private appService: AppService,
    private router: Router) { }

  ngOnInit(): void {
    this.appService.setUserLoggedIn(false)
    this.initLoginForm();
  }
  initLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }
  onLoginFormSubmit() {
    this.submitted = true;
    // if (this.loginForm.invalid) return
    if (this.loginForm.invalid) {
      return;
    } else {
      this.load = false;
    }
    this.loginData.username = this.loginForm.value.username;
    this.loginData.password = this.loginForm.value.password;
    //console.log("login requested");
    //console.log(this.loginData);

    this.cmnService.userLogin(this.loginData)
    .subscribe((response) => {
      if (response.status === 2) {
        localStorage.setItem('user_id', response.info['user_id']);
        localStorage.setItem('username', response.info['username']);
        localStorage.setItem('token', response.info['token']);
        localStorage.setItem('loggedIn', '1');
        localStorage.setItem('groupname', response.info['groupname']);
        localStorage.setItem('groupid', response.info['groupid']);
        localStorage.setItem('sectorname', response.info['sectorname']);
        localStorage.setItem('sectorid', response.info['sectorid']);
        localStorage.setItem('roleId', response.info['role_id']);
        this.appService.setUserLoggedIn(true)
        this.initialLoad(response.info.user_id)
      } else {
        alert(`${response.message}`);
        this.submitted = false;
        this.load = true;
      }
    })

    // this.cmnService.postRequest('api-login/', this.loginData)
    //   .subscribe((response) => {
    //     //console.log("api response")
    //     console.log(response);
    //     if (response.status === 2) {
    //       localStorage.setItem('user_id', response.info['user_id']);
    //       localStorage.setItem('username', response.info['username']);
    //       localStorage.setItem('token', response.info['token']);
    //       localStorage.setItem('loggedIn', '1');
    //       localStorage.setItem('groupname', response.info['groupname']);
    //       localStorage.setItem('groupid', response.info['groupid']);
    //       localStorage.setItem('sectorname', response.info['sectorname']);
    //       localStorage.setItem('sectorid', response.info['sectorid']);
    //       localStorage.setItem('roleId', response.info['role_id']);
    //       this.appService.setUserLoggedIn(true)
    //       this.initialLoad(response.info.user_id)
    //       //  this.router.navigate(['/']);
    //       // if(this.activityList.length == 1){
    //       //   alert(this.activityList.length == 1)
    //       //   this.router.navigate(['/']);
    //       // }else{
    //       //   this.router.navigate(['switch-role']);
    //       // }
    //       //  alert(`${response.message}`);
    //     } else {
    //       alert(`${response.message}`);
    //       this.submitted = false;
    //       this.load = true;
    //     }
    //   }
    //   )
  }
  initialLoad(userId) {
    var dObj = {
      user_id: userId
    }
    this.cmnService.getAllRoles(dObj)
    .subscribe((response) => {
      this.activityList = response.message;
      if (this.activityList.length == 1) {
        this.switchRoleId = response.message[0].id;
        this.switcholeName = response.message[0].name;
        localStorage.setItem('switchRoleId', this.switchRoleId);
        localStorage.setItem('switcholeName', this.switcholeName);
        localStorage.setItem('displaySwitchRole', 'false');
        localStorage.setItem('displayNavigation', 'false');
        // this.router.navigate(['/']);
        this.appService.setUserLoggedIn(true)
        // window.location.href = '/';
        window.location.href = response.message[0].redirect_link;
      } else {
        this.router.navigate(['switch-role']);
        // window.location.href = '/switch-role';
      }
    }
    )
    // this.cmnService.postRequest('api/all-roles/', { user_id: userId })
    //   .subscribe((response) => {
    //     this.activityList = response.message;
    //     if (this.activityList.length == 1) {
    //       this.switchRoleId = response.message[0].id;
    //       this.switcholeName = response.message[0].name;
    //       localStorage.setItem('switchRoleId', this.switchRoleId);
    //       localStorage.setItem('switcholeName', this.switcholeName);
    //       localStorage.setItem('displaySwitchRole', 'false');
    //       localStorage.setItem('displayNavigation', 'false');
    //       // this.router.navigate(['/']);
    //       this.appService.setUserLoggedIn(true)
    //       // window.location.href = '/';
    //       window.location.href = response.message[0].redirect_link;
    //     } else {
    //       this.router.navigate(['switch-role']);
    //       // window.location.href = '/switch-role';
    //     }
    //   }
    //   )
  }
}
