import { Injectable } from '@angular/core';
import { CommonService } from '../../../core/common.service';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  navbarmenus = [];
  menus
  // menuId =localStorage.getItem('menuId');
  switchRoleId = localStorage.getItem('switchRoleId');
  constructor(private cmnService: CommonService) {
    //  if(this.navbarmenus.length) {
    //  this.navbarmenus;
    //  }
    // else{
    //   this.initialLoad();
    // }
    // this.initialLoad(2);
  }

  initialLoad(menuId, name, id) {


    this.cmnService.getOuterChildMenu({
      "role_id": this.switchRoleId, "menu_id": menuId, "entity_id": id,
      "content_type": name
    }).subscribe(res => {
          // this.navbarmenus = res.message
          // this.menus  =this.navbarmenus.shift();
          for (let x of res.message) {

            if (x.name == 'Details') {
              this.navbarmenus = res.message,
                this.menus = this.navbarmenus.shift();
            }
            else {
              this.navbarmenus = res.message
            }
          }
          if (menuId == 5) {
            this.navbarmenus = this.navbarmenus.filter(user => user.id !== 29);
            this.navbarmenus = this.navbarmenus.filter(user => user.id !== 35);
            this.navbarmenus = this.navbarmenus.filter(user => user.id !== 48);
            let MAT =  {
              "slug": "mat",
              "id": 54,
              "name": "MAT Hierarchy",
              "icon": "",
              "url": "matListing/listing",
              "add": true,
              "edit": true,
              "delete": false,
              "view": true,
              "child": []
            }
             this.navbarmenus.splice(2, 0, MAT);
          }
        }
      )

  }

  findMenus(menu_name) {
    return this.navbarmenus.find(elements => elements.name === menu_name)
  }
  getMenus(name) {


    switch (name) {
      case 'Team': {
        localStorage.setItem('navmenuadd', this.findMenus("Team").add);
        localStorage.setItem('navmenuedit', this.findMenus("Team").edit);
        localStorage.setItem('navmenuview', this.findMenus("Team").view);
        break;
      }
      case 'Meetings': {

        localStorage.setItem('navmenuadd', this.findMenus("Meetings").add);
        localStorage.setItem('navmenuedit', this.findMenus("Meetings").edit);
        localStorage.setItem('navmenuview', this.findMenus("Meetings").view);
        localStorage.setItem('navmenudelete', this.findMenus("Meetings").delete);
        break;
      }
      case 'Milestone': {

        localStorage.setItem('navmenuadd', this.findMenus("Milestone").add);
        localStorage.setItem('navmenuedit', this.findMenus("Milestone").edit);
        localStorage.setItem('navmenuview', this.findMenus("Milestone").view);
        localStorage.setItem('navmenudelete', this.findMenus("Milestone").delete);
        break;
      }
      case 'Document': {

        localStorage.setItem('navmenuadd', this.findMenus("Document").add);
        localStorage.setItem('navmenuedit', this.findMenus("Document").edit);
        localStorage.setItem('navmenuview', this.findMenus("Document").view);
        localStorage.setItem('navmenudelete', this.findMenus("Document").delete);
        break;
      }
      case 'Activity': {

        localStorage.setItem('navmenuadd', this.findMenus("Activity").add);
        localStorage.setItem('navmenuedit', this.findMenus("Activity").edit);
        localStorage.setItem('navmenuview', this.findMenus("Activity").view);
        localStorage.setItem('navmenudelete', this.findMenus("Activity").delete);
        break;
      }
      case 'Task': {

        localStorage.setItem('navmenuadd', this.findMenus("Task").add);
        localStorage.setItem('navmenuedit', this.findMenus("Task").edit);
        localStorage.setItem('navmenuview', this.findMenus("Task").view);
        localStorage.setItem('navmenudelete', this.findMenus("Task").delete);
        break;
      }
      case 'Funder': {

        break;
      }
      case 'Rating': {

        break;
      }
      case 'MAT Hierarchy':{
        break;
      }
      default:
        break;
    }
  }

}