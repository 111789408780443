import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardsService {
  logIn;
  currentUrl;
  userId = localStorage.getItem('user_id');
  switchRoleId = localStorage.getItem('switchRoleId');
  constructor(readonly route: Router, readonly commonService: CommonService ) { }
  canActivate(aRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    var dObj = { "user_id": this.userId, "role_id": this.switchRoleId };
    this.commonService.getDashboardNotification(dObj)
      .subscribe(
        res => {
          // console.log(res);
          
        }
      )

    this.logIn = localStorage.getItem('loggedIn');
    this.currentUrl = window.location.href;
      if ( !this.logIn ) {
        localStorage.setItem('navigateUrl', this.currentUrl );
        this.route.navigate(['/login']);
        // this.commonService.showNotification('<b>Please Login !!!</b>', 'danger');
        // alert("please login")
        return false;
      }
      return true;
  }
}
