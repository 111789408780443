import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private userLoggedIn = new Subject<boolean>();

  constructor() {
    this.userLoggedIn.next(false);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    // console.log("setUserLoggedIn" + userLoggedIn)
    this.userLoggedIn.next(userLoggedIn);
    // console.log("setUserLoggedIn" + JSON.stringify(userLoggedIn))
  }

  getUserLoggedIn(): Observable<boolean> {
    // console.log("getUserLoggedIn " + JSON.stringify(this.userLoggedIn))
    return this.userLoggedIn.asObservable();
  }
}
