
import { Component } from '@angular/core';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent { 
 
}
