import { Injectable } from '@angular/core';
import { CommonService } from '../../core/common.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultService {
  navItems = [];
  todaysTaskList = [];
  todaysMeetingList = [];
  upcomingTaskList = [];
  upcomingmeetingList = [];
  userId = localStorage.getItem('user_id');
  imageVal = "assets/user.png";
  switchRoleId = localStorage.getItem('switchRoleId');
  constructor(private cmnService: CommonService) {
    this.profileImage(this.userId);
    this.notification();
    if (this.navItems.length) {
      this.navItems;
    }
    else {
      this.initialLoad();
    }
  }

  profileImage(userId) {
    var dObj = { user_id: this.userId };
    this.cmnService.getProfile(dObj)
      .subscribe(
        res => {
          if((res.message.image).trim())
            this.imageVal = (res.message.image)? res.message.image : 'assets/user.png'
          else
           this.imageVal = 'assets/user.png';
        } 
      )
    // this.cmnService.postRequest('api/profile/',{user_id:userId})
    // // .subscribe((response) => {
    // //   this.imageVal=response.message.image;
    // // }
    // // )
    // .subscribe(
    // res => this.imageVal = res.message.image
    // )
  }

  notification() {
    var dObj = { "user_id": this.userId, "role_id": this.switchRoleId };
    this.cmnService.getDashboardNotification(dObj)
      .subscribe(
        res => {
          this.todaysTaskList = res.today_task,
          this.todaysMeetingList = res.today_meeting,
          this.upcomingTaskList = res.upcomming_task,
          this.upcomingmeetingList = res.upcomming_meeting
        }
      )
    // this.cmnService.postRequest('dashboard-notification/', { "user_id": this.userId, "role_id": this.switchRoleId })
    //   .subscribe(
    //     res => {
    //       this.todaysTaskList = res.today_task,
    //       this.todaysMeetingList = res.today_meeting,
    //       this.upcomingTaskList = res.upcomming_task,
    //       this.upcomingmeetingList = res.upcomming_meeting
    //     }
    //   )
  }

  initialLoad() {
    // alert("hiii")
    //  this.cmnService.postRequest('api/main-menu/',{"role_id":this.roleId})
    // this.cmnService.postRequest('api/main-menu/', { "role_id": this.switchRoleId })
    //   .subscribe(
    //     res => this.navItems = res.message
    //   )

    var dObj = { "role_id": this.switchRoleId };
    this.cmnService.getMainMenu(dObj)
      .subscribe(
        res => {
          this.navItems = res.message.slice(1);
          // console.log(this.navItems);
          
        }
      )
  }
  findMenus(menu_name) {
    return this.navItems.find(elements => elements.id === menu_name)
  }
  getMenus(number) {
    switch (number) {
      case 2: {

        break;
      }
      case 3: {
        localStorage.setItem('menuadd', this.findMenus(3).add);
        localStorage.setItem('menuedit', this.findMenus(3).edit);
        localStorage.setItem('menuview', this.findMenus(3).view);
        localStorage.setItem('menudelete', this.findMenus(3).delete);
        break;
      }
      case 4: {
        localStorage.setItem('menuadd', this.findMenus(4).add);
        localStorage.setItem('menuedit', this.findMenus(4).edit);
        localStorage.setItem('menuview', this.findMenus(4).view);
        localStorage.setItem('menudelete', this.findMenus(4).delete);
        break;
      }
      case 5: {
        localStorage.setItem('menuadd', this.findMenus(5).add);
        localStorage.setItem('menuedit', this.findMenus(5).edit);
        localStorage.setItem('menuview', this.findMenus(5).view);
        localStorage.setItem('menudelete', this.findMenus(5).delete);
        break;
      }
      case 6: {
        localStorage.setItem('menuadd', this.findMenus(6).add);
        localStorage.setItem('menuedit', this.findMenus(6).edit);
        localStorage.setItem('menuview', this.findMenus(6).view);
        localStorage.setItem('menudelete', this.findMenus(6).delete);
        break;
      }
      case 7: {

        break;
      }
      case 38: {
        localStorage.setItem('cpemenuedit', this.findMenus(38).edit);
        break;
      }
      case 37: {
        localStorage.setItem('qchmenuedit', this.findMenus(37).edit);
        localStorage.setItem('qchmenuview', this.findMenus(37).view);
        break;
      }
      default:
        break;
    }
  }

}
