// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_fileupload--hidden {
    display: none;
  }
  .img-avatar1, .avatar > img, .img-circle {
    /* max-width: 100%;
    height: 100%;
    border-radius: 50em; */
    border-radius: 50em;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
}
.fname{
    margin-left: 50px;
    /* margin-top: 8px; */
    font-size: 13px;
}
.list-group-item-accent-warning {
    border-left: none;
}
.col-md-6.imagetextbox {
    /* margin-top: 15px; */
    display: flex;
    padding-left: 1%;
    display: flex;
}

/* .documentButton{
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */
img.fa.fa-cogs.bg-primary.font-2xl.mr-3.float-left {
    height: 100px;
    width: auto;
}
.btn-block {
    display: block;
    width: 47%;
    margin-top: 10px;
}
.btn{
    padding: 6px;
}

button {
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/views/profile/profile.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;EACA;IACE;;0BAEsB;IACtB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,eAAe;AACnB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,sBAAsB;IACtB,aAAa;IACb,gBAAgB;IAChB,aAAa;AACjB;;AAEA;;;;;;;;;;;;;GAaG;AACH;IACI,aAAa;IACb,WAAW;AACf;AACA;IACI,cAAc;IACd,UAAU;IACV,gBAAgB;AACpB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,0BAA0B;AAC9B","sourcesContent":[".input_fileupload--hidden {\r\n    display: none;\r\n  }\r\n  .img-avatar1, .avatar > img, .img-circle {\r\n    /* max-width: 100%;\r\n    height: 100%;\r\n    border-radius: 50em; */\r\n    border-radius: 50em;\r\n    max-width: 100%;\r\n    min-height: 100%;\r\n    min-width: 100%;\r\n}\r\n.fname{\r\n    margin-left: 50px;\r\n    /* margin-top: 8px; */\r\n    font-size: 13px;\r\n}\r\n.list-group-item-accent-warning {\r\n    border-left: none;\r\n}\r\n.col-md-6.imagetextbox {\r\n    /* margin-top: 15px; */\r\n    display: flex;\r\n    padding-left: 1%;\r\n    display: flex;\r\n}\r\n\r\n/* .documentButton{\r\n    width: 100%;\r\n    height: calc(1.5em + 0.75rem + 2px);\r\n    padding: 0.375rem 0.75rem;\r\n    font-size: 0.875rem;\r\n    font-weight: 400;\r\n    line-height: 1.5;\r\n    color: #5c6873;\r\n    background-color: #fff;\r\n    background-clip: padding-box;\r\n    border: 1px solid #e4e7ea;\r\n    border-radius: 0.25rem;\r\n    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\r\n} */\r\nimg.fa.fa-cogs.bg-primary.font-2xl.mr-3.float-left {\r\n    height: 100px;\r\n    width: auto;\r\n}\r\n.btn-block {\r\n    display: block;\r\n    width: 47%;\r\n    margin-top: 10px;\r\n}\r\n.btn{\r\n    padding: 6px;\r\n}\r\n\r\nbutton {\r\n    cursor: pointer;\r\n    border: none;\r\n    background: none;\r\n    outline: none;\r\n    text-decoration: underline;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
