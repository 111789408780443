import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { LocationStrategy,PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from  '@angular/common/http';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
//import { CKEditorModule } from 'ngx-ckeditor';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { FormsModule } from '@angular/forms';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { SwitchRoleComponent } from './views/switch-role/switch-role.component';
import { LogoutComponent } from './views/logout/logout.component';
import { RegisterComponent } from './views/register/register.component';

import { CKEditorModule } from 'ckeditor4-angular';
import { NgxSpinnerModule } from 'ngx-spinner';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgChartsModule } from 'ng2-charts';
import { ErrorMessagesModule } from './views/shared/error-messages/error-messages.module';
import { ProfileComponent } from './views/profile/profile.component';
import { NewloginComponent } from './views/newlogin/newlogin.component';

@NgModule({
  imports: [
  FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    NgxMaterialTimepickerModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    CKEditorModule,
    ErrorMessagesModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    NgChartsModule,
    HttpClientModule,
    NgxSpinnerModule
  ],
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    SwitchRoleComponent,
    ProfileComponent,
    NewloginComponent,
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: PathLocationStrategy
  }],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
