import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../core/common.service';
import { DefaultService } from '../../containers/default-layout/default.service';
// import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  @ViewChild('fileUploadElementRef') fileUploadElementRef;
  userId = localStorage.getItem('user_id');
  firstname;
  lastname;
  imageVal;
  userprofileid;
  pageload = true;
  load = true;
  // profileForm: FormGroup = new FormGroup({
  //   'image' : new FormControl("")
  // })

  constructor(private cmnService: CommonService, private defaultService: DefaultService) { }

  ngOnInit(): void {
    this.initialLoad();
    this.defaultService.profileImage(this.userId);
  }

  initialLoad() {
    this.load = false;
    var dObj = { user_id: this.userId };
    this.cmnService.getProfile(dObj )
      .subscribe((response) => {
        this.pageload = false;
        this.load = true;
        this.firstname = response.message.firstname;
        this.lastname = response.message.lastname;
        this.imageVal = response.message.image;
        this.userprofileid = response.message.userprofileid;
      }
      )
    // this.cmnService.postRequest('api/profile/', { user_id: this.userId })
    //   .subscribe((response) => {
    //     this.pageload = false;
    //     this.load = true;
    //     this.firstname = response.message.firstname;
    //     this.lastname = response.message.lastname;
    //     this.imageVal = response.message.image;
    //     this.userprofileid = response.message.userprofileid;
    //   }
    //   )
  }

  /***************************image upload ***************************************/
  fireNativeFileUploadButton() {
    if (this.fileUploadElementRef)
      this.fileUploadElementRef.nativeElement.click();
  }
  // /*******************************image upload ****************************************/
  image; imageFileName;
  onImagePicked(event: Event): void {
    this.image = (event.target as HTMLInputElement).files[0];
    this.imageFileName = (event.target as HTMLInputElement).files[0].name;
    this.onImageUpload();
  }


  onImageUpload() {
    this.load = false;
    const formData = new FormData();
    formData.append('profile', this.image)
    this.cmnService.updateProfile(this.userprofileid, formData)
    .subscribe((response) => {
      if (response['status'] === 2) {
        this.load = true;
        this.initialLoad();
        this.defaultService.profileImage(this.userId);
        alert(`${response['message']}`);
        // this.router.navigate([`/document/listing/${this.type}/${this.eoiId}`])
      } else {
        alert(`${response['message']}`);
        this.load = true;
      }
    }
    )

    // this.cmnService.postRequest(`api/update-profile/${this.userprofileid}/`, formData)
    //   .subscribe((response) => {
    //     if (response['status'] === 2) {
    //       this.load = true;
    //       this.initialLoad();
    //       this.defaultService.profileImage(this.userId);
    //       alert(`${response['message']}`);
    //       // this.router.navigate([`/document/listing/${this.type}/${this.eoiId}`])
    //     } else {
    //       alert(`${response['message']}`);
    //       this.load = true;
    //     }
    //   }
    //   )
  }

}
