import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';  

@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnInit {

  constructor(private httpClient : HttpClient) {}
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
    var navtypeValue = localStorage.getItem('navtype');
    if (navtypeValue !== null) {
        // console.log('navtype value:', navtypeValue);
    } else {
        // console.log('navtype key not found in localStorage');
    }
  }

  gettrial(url: string): Observable<any> {

    return this.httpClient.get(url);
  }

  get(url: string): Observable<any> {

    return this.httpClient.get(this.getUrl(url))
    // return this.httpClient.get(url);
  }

  post(url: string, body: any | null): Observable<any> {

    return this.httpClient.post(this.getUrl(url), body)
  }

  update(url: string, value: any|null): Observable<Object> {
    return this.httpClient.put(this.getUrl(url), value);
  }

  private getUrl(path: string) {
    const apiBaseUrl = environment.apiUrl;
    return apiBaseUrl + path;
  }

}
