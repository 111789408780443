// import { Component, OnInit } from '@angular/core';
// import { Router, NavigationEnd } from '@angular/router';

// @Component({
//   selector: 'body',
//   template: '<router-outlet></router-outlet>'
// })
// export class AppComponent implements OnInit {
//   constructor(private router: Router) { }

//   ngOnInit() {
//     this.router.events.subscribe((evt) => {
//       if (!(evt instanceof NavigationEnd)) {
//         return;
//       }
//       window.scrollTo(0, 0);
//     });
//   }
// }


import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { AppService } from './_services/app.service';
import { AuthService } from './core/authentication/auth.service';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'PMS';


  

  constructor(private idle: Idle, private keepalive: Keepalive, private authService: AuthService,
    // private router: Router, private modalService: BsModalService, private appService: AppService) {
    private router: Router, private appService: AppService) {
    // sets an idle timeout of 60 minutes.
    idle.setIdle(3600);
    // sets a timeout period of 5 seconds. after60 mintues of inactivity, the user will be considered timed out.
    idle.setTimeout(5);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      // this.childModal.hide();
      this.idleState = 'Timed out!';
      // console.log(this.idleState + "  " + new Date().toUTCString());
      this.timedOut = true;
      alert("Session expired. Please login.");
      this.authService.logout();
      this.router.navigate(['/logout']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      // this.childModal.show();
      // console.log(this.idleState + "  " + new Date().toUTCString());
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      // console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
        this.idleState = 'Idle state started';
      } else {
        idle.stop();
        this.idleState = 'Idle state stopped';
      }
    })

    this.reset();
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });


    // console.log("EveryTime");
    
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Idle state started';
    this.timedOut = false;
  }
}
