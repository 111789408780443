// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  margin-bottom: 20px;
  width: 35%;
}

.text-msg {
  font-size: 17px;
  margin-top: 60px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/logout/logout.component.scss"],"names":[],"mappings":"AAAA;EAAO,mBAAA;EAAoB,UAAA;AAG3B;;AAFA;EACI,eAAA;EACA,gBAAA;AAKJ","sourcesContent":[".logo{ margin-bottom: 20px;width: 35%}\r\n.text-msg{\r\n    font-size: 17px;\r\n    margin-top: 60px\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
