import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { DefaultService } from '../../containers/default-layout/default.service';
import { AppService } from "../../_services/app.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private readonly router: Router,private readonly defaultService:DefaultService, 
    private appService: AppService
    ) { }

    logout() {
      localStorage.clear();
      this.router.navigate(['/login']);
      this.defaultService.navItems=[]
      // this.defaultService.imageVal=""
      this.defaultService.imageVal="assets/user.png";
      this.appService.setUserLoggedIn(false)
      return
    }
  
}
