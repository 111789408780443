import { Component, OnInit } from '@angular/core';
import { navItems1 } from '../../_nav';
import { navItems } from '../../_nav';
import { AuthService } from '../../core/authentication/auth.service';
import { Router } from '@angular/router';
import { CommonService } from '../../core/common.service';
import { DefaultService } from './default.service';
import { NavbarService } from '../../views/program/programnavbar/programnavbar.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  username = localStorage.getItem('username');
  switchRoleId = localStorage.getItem('switchRoleId');
  userId = localStorage.getItem('user_id');
  switcholeName = localStorage.getItem('switcholeName');
  displaySwitchRole = localStorage.getItem('displaySwitchRole');
  displayNavigation = localStorage.getItem('displayNavigation');
  switchroleUrl=localStorage.getItem('switchroleUrl');
  menuId; program;
  firstname;
  navItemsforrender ;
  
  constructor(
    private authService: AuthService, private router: Router, private cmnService: CommonService,
    public defaultService: DefaultService, private navbarService: NavbarService,

  ) {

  }

  public sidebarMinimized = false;
  public navItems;x

  

  isActive(url: string, id: any): boolean {
    // console.log( this.router.isActive(url, true));
    // return this.router.isActive(url, true);

    var navtypeValue = localStorage.getItem('menuId');
    // console.log(url, "and", id, "before loop");
    
    var value ;
    // console.log(navtypeValue);
    
    if (id == navtypeValue) {
      // console.log(id);
      // console.log(true);
      value = true
    } else {
      // console.log(id, false);
      value = false
    }
;
  
  // console.log(url, "and", id, "outside loop");
  //  console.log(value);

  return value
    
  }

  ngOnInit() {
    var dObj = { user_id: this.userId };
    this.cmnService.getProfile(dObj )
      .subscribe((response) => {
        
        this.firstname = response.message.firstname;
      }
      )
    
  }

  


  act = ""
  navigateMeeting(type, id, taskid, action) {
    this.act = action;
    if (!this.act) {
      localStorage.setItem('navmenuedit', 'false');
    }
    this.menuId = (type == "proposal") ? '4' : (type == "project") ? '5' : (type == "engagement") ? '6'
      : '3';
    this.program = (type == "proposal") ? 'proposal' : (type == "project") ? 'project' : (type == "engagement") ? 'engagement'
      : 'program';
    localStorage.setItem('menuId', this.menuId);
    localStorage.setItem('program', this.program);
    localStorage.setItem('eoiId', id);
    //  this.router.navigate([`/meeting/detail/${taskid}/${id}/${type}`])
    this.router.navigate([`/meeting/detail/${taskid}/${id}/${type}`], { queryParams: { action: action } });
  }
  navigateTask(id, type, entityId, action) {
    // if(!action){
    //   localStorage.setItem('navmenuedit','false');
    //  }
    this.menuId = (type == "proposal") ? '4' : (type == "project") ? '5' : (type == "engagement") ? '6'
      : '3';
    this.program = (type == "proposal") ? 'proposal' : (type == "project") ? 'project' : (type == "engagement") ? 'engagement'
      : 'program';

    localStorage.setItem('menuId', this.menuId);
    localStorage.setItem('program', this.program);
    localStorage.setItem('eoiId', entityId);

    this.router.navigate([`/task/detail/${id}/${type}/${entityId}/task`])
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  logOut() {
    this.authService.logout();
    this.router.navigate(['/logout']);
  }
}
