// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app, .app-dashboard, .app-root {
  display: flex;
  min-height: initial !important;
  background-color: #e4e5e6 !important;
}

.main {
  background: #e4e5e6;
  padding: 150px 0 1px 0;
  color: #224290;
  text-align: center;
}

.container {
  width: 500px;
  margin: auto;
  box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px 0 20px 0;
  background-color: #fff;
}

.signup {
  margin-bottom: 100px;
}

.signup-content {
  display: flex;
  align-items: center;
}

.signup-image {
  padding: 15px;
  width: 100%;
  margin: auto;
  text-align: center;
}

button {
  margin: auto;
  margin-top: 10px;
  width: 100px;
}

img {
  width: auto;
  height: auto;
  aspect-ratio: 1/1;
}

figure {
  margin: 0;
}

h2 {
  margin: 10px;
  font-family: "Montserrat", sans-serif !important;
  padding-left: 5px;
  font-weight: bold;
  font-size: xx-large;
}

.signup-form {
  width: 100%;
  margin: 0 0 0 20px;
  overflow: visible;
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/app/views/newlogin/newlogin.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,oCAAA;AACJ;;AACA;EACI,mBAAA;EACA,sBAAA;EACA,cAAA;EACA,kBAAA;AAEJ;;AACA;EACI,YAAA;EACA,YAAA;EACA,qDAAA;EACA,mBAAA;EACA,sBAAA;EACD,sBAAA;AAEH;;AACA;EACI,oBAAA;AAEJ;;AACA;EACI,aAAA;EACA,mBAAA;AAEJ;;AACA;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,YAAA;EACA,gBAAA;EACA,YAAA;AAGJ;;AADA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AAIJ;;AAFA;EACI,SAAA;AAKJ;;AAFA;EACI,YAAA;EACA,gDAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;AAKJ;;AAHA;EACI,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;AAMJ","sourcesContent":[".app, .app-dashboard, .app-root {\r\n    display: flex;\r\n    min-height: initial !important;\r\n    background-color: #e4e5e6 !important;\r\n}\r\n.main {\r\n    background: #e4e5e6;\r\n    padding: 150px 0 1px 0;\r\n    color: #224290;\r\n    text-align: center;\r\n}\r\n\r\n.container {\r\n    width: 500px;\r\n    margin: auto;\r\n    box-shadow: 0 15px 16.83px .17px rgba(0, 0, 0, .05);\r\n    border-radius: 20px;\r\n    padding: 20px 0 20px 0;\r\n   background-color: #fff;\r\n}\r\n\r\n.signup {\r\n    margin-bottom: 100px;\r\n}\r\n\r\n.signup-content {\r\n    display: flex;\r\n    align-items: center\r\n}\r\n\r\n.signup-image {\r\n    padding: 15px;\r\n    width: 100%;\r\n    margin: auto;\r\n    text-align: center;\r\n}\r\nbutton{\r\n    margin: auto;\r\n    margin-top: 10px;\r\n    width: 100px\r\n}\r\nimg{\r\n    width: auto;\r\n    height: auto;\r\n    aspect-ratio: 1/1;\r\n}\r\nfigure {\r\n    margin: 0\r\n}\r\n\r\nh2{\r\n    margin: 10px;\r\n    font-family: 'Montserrat', sans-serif !important;\r\n    padding-left:  5px;\r\n    font-weight: bold;\r\n    font-size: xx-large;\r\n}\r\n.signup-form {\r\n    width: 100%;\r\n    margin: 0 0 0 20px ;\r\n    overflow: visible;\r\n    text-align: justify;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
