import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })
// export class InterceptorService {

//   constructor() { }
// }

@Injectable()
export class InterceptorService implements HttpInterceptor {
  authReq
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentToken = localStorage.getItem('token');
    const aauthHeader = (currentToken) ? `token ${currentToken}` : ''
      this.authReq = request.clone({
    headers: new HttpHeaders({
      Authorization: aauthHeader
    })
  });
    return next.handle(this.authReq);
  }
}

